//import './bootstrap';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import "@fontsource/readex-pro";

window.Alpine = Alpine;

Alpine.plugin(focus);

Alpine.start();
